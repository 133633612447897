import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React from 'react'
import { TextSize } from 'tuix-webcomponents'
import './BulletPoints.scss'

interface Props {
    texts: readonly string[]
}

export default ({ texts }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <ul className="bullet_list">
            <TuixTextComponent size={TextSize.Body1} dark={isDarkMode} className="bullet_list_text">
                {texts.map((bullet) => (
                    <li key={bullet} className="bulletPoint">
                        {bullet}
                    </li>
                ))}
            </TuixTextComponent>
        </ul>
    )
}
