import React, { createContext } from 'react'
import Page from 'components/Page'
import { getDataByLocale, Node } from '../../utils/dataHelpers'
import { graphql, PageProps } from 'gatsby'
import { Locale } from 'utils/enums'
import Section from 'components/Section'
import MainImage from 'components/MainImage'
import { MetaData } from 'components/Seo'
import BlogEntry from 'components/BlogEntry'

interface Props extends PageProps {
    pageContext: {
        slug: string
        locale: Locale
        id: string
    }
    data: Record<string, { nodes: readonly Node<any>[] }>
}

export const slugContext = createContext()

export default ({ pageContext: { locale, id, slug }, data }: Props): JSX.Element => {
    const {
        contentBlog: { nodes: contentBlog },
        contentMetaData: { nodes: nodesMetaData },
        oppositeBlogSlug: { nodes: oppositeBlogSlug },
    } = data

    const {
        title,
        createdAt,
        articleText: { raw: richText },
        coverImage,
    } = contentBlog[0]
    const { slug: oppositeSlug } = oppositeBlogSlug[0]
    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)
    return (
        <slugContext.Provider value={{ slug: oppositeSlug }}>
            <Page metaData={metaData} locale={locale}>
                <Section>
                    <MainImage imageURL={coverImage.file.url} imageURLDark={coverImage.file.url} />
                </Section>
                <Section>
                    <BlogEntry title={title} createdAt={createdAt} richText={richText} />
                </Section>
            </Page>
        </slugContext.Provider>
    )
}

export const query = graphql`
    query QueryBlogPost($slug: String!, $locale: String!, $id: String!) {
        contentBlog: allContentfulBlogPost(
            filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
        ) {
            nodes {
                createdBy
                createdAt
                slug
                node_locale
                title
                articleText {
                    raw
                }
                miniatureImage {
                    file {
                        url
                    }
                }
                coverImage {
                    file {
                        url
                    }
                }
            }
        }

        oppositeBlogSlug: allContentfulBlogPost(
            filter: { contentful_id: { eq: $id }, node_locale: { ne: $locale } }
        ) {
            nodes {
                slug
            }
        }

        contentMetaData: allContentfulSeo(
            filter: { contentful_id: { eq: "2MOy3DMDolAMyiay8EvErc" } }
        ) {
            nodes {
                title
                url
                description {
                    description
                }
                image {
                    file {
                        url
                    }
                }
                node_locale
            }
        }
    }
`
