import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import * as React from 'react'
import { TextSize } from 'tuix-webcomponents'
import './RichTuixTextBlog.scss'

type Props = {
    children: JSX.Element
    size: TextSize
    tag: TAG
}

export enum TAG {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
    P = 'p',
}

export default ({ children, size, tag }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <>
            <TuixTextComponent size={size} dark={isDarkMode} className="rich-tuix-text-blog">
                {React.createElement(tag, {}, children)}
            </TuixTextComponent>
            <br />
        </>
    )
}
