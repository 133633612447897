export const findNodes = (array, condition) => {
    return array.find((item) => condition(item))
}

export const getInternalContent = (input: string): { name: string; content: string } => {
    const pattern = /```(\w+)\s*([\s\S]+?)```/
    const match = pattern.exec(input)
    let name = ''
    let content = ''
    if (!match) return { name, content }
    name = match[1].trim() || ''
    content = match[2].trim() || ''
    return { name, content }
}
export const isSupportedLanguage = (language: string): boolean => {
    return Object.values(CodeBlockSupportedLanguages).includes(
        language as CodeBlockSupportedLanguages,
    )
}
export enum CodeBlockSupportedLanguages {
    BASH = 'bash',
    C = 'c',
    CLOJURE = 'clojure',
    CPP = 'cpp',
    CSHARP = 'csharp',
    DART = 'dart',
    ELIXIR = 'elixir',
    ELM = 'elm',
    ERLANG = 'erlang',
    FSHARP = 'fsharp',
    GRAPHQL = 'graphql',
    GO = 'go',
    GROOBY = 'groovy',
    HASKELL = 'haskell',
    HTML = 'html',
    JAVA = 'java',
    JAVASCRIPT = 'javascript',
    JSX = 'jsx',
    JULIA = 'julia',
    KOTLIN = 'kotlin',
    LISP = 'lisp',
    MAKEFILE = 'makefile',
    MATLAB = 'matlab',
    OBJECTIVEC = 'objectivec',
    OCAML = 'ocaml',
    PHP = 'php',
    PYTHON = 'python',
    R = 'r',
    RUBY = 'ruby',
    RUST = 'rust',
    SCALA = 'scala',
    SQL = 'sql',
    SWIFT = 'swift',
    TSX = 'tsx',
    TYPESCRIPT = 'typescript',
}
