import LogosServices from 'components/LogosServices'
import Slider from 'components/Slider'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React from 'react'
import { TextSize } from 'tuix-webcomponents'
import './UxUiTools.scss'

type File = {
    url: string
    fileName: string
}

type Logos = {
    file: File
}

type UxUiTools = {
    sentence: string
    logos: Logos[]
}

export default ({ sentence, logos }: UxUiTools): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const items = logos.map((logo) => <LogosServices imageURL={logo.file.url}></LogosServices>)

    return (
        <div className="uxUi-tools-container">
            <div className="uxUi-tools-paragraph">
                <TuixTextComponent size={TextSize.Body1} dark={isDarkMode}>
                    <p>{sentence}</p>
                </TuixTextComponent>
            </div>
            <Slider items={items}></Slider>
        </div>
    )
}
