import '@fontsource/rubik'
import '@fontsource/rubik/300.css'
import Branches from 'components/Branches'
import { IconWithTextModel } from 'components/IconWithText/IconWithText'
import MainImage from 'components/MainImage'
import { MainImageModel } from 'components/MainImage/MainImage'
import Newsletter from 'components/Newsletter'
import { CustomerFile, NewsletterProps } from 'components/Newsletter/Newsletter'
import Page from 'components/Page'
import Section from 'components/Section'
import { SectionModel } from 'components/Section/Section'
import { MetaData } from 'components/Seo/types'
import TextWithImage from 'components/TextWithImage'
import { TextWithImageModel } from 'components/TextWithImage/TextWithImage'
import { usePageContent } from 'components/hooks/usePageContent/home/usePageContent'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useEffect } from 'react'
import 'tuix-webcomponents'
import { MainVariant } from 'tuix-webcomponents'
import useWindowDimensions from 'utils/window'
import { getDataByLocale, mappingEdge, mappingNode } from '../utils/dataHelpers'
import { Locale } from '../utils/enums'
import { getLanguageByLocale } from '../utils/language'
import ArrowLeft from './../images/arrowLeft.svg'
import ArrowRight from './../images/arrowRight.svg'
import './index.scss'
import CallToAction from 'components/CallToAction'

interface Props {
    locale: Locale
}

export interface HappyCustomersModel {
    buttonText: string
    subtitle: string
    title: string
    customerLogos: readonly CustomerFile[]
    node_locale: Locale
}

const IndexPage = ({ locale = Locale.de_DE }: Props): JSX.Element => {
    const { width } = useWindowDimensions()

    const {
        nodesIconWithText,
        nodesMainImage,
        nodesSection1,
        nodesSection2,
        nodesSection3,
        nodesSection4,
        nodesTextWithImageEdges,
        nodesHappyCustomers,
        nodesMetaData,
        nodesNewsletter,
    } = usePageContent()

    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)

    const newsletterForm = getDataByLocale<NewsletterProps>(nodesNewsletter, locale)

    const {
        backgroundImage: {
            file: { url },
        },
        backgroundImageDark: {
            file: { url: imageUrlDark },
        },
        title,
        titleSize,
        textVariantTitle,
        subtitle,
        subTitleSize,
        textVariantSubtitle,
        textVariantSubtitleDark,
        textPosition,
        buttonText,
    } = getDataByLocale<MainImageModel>(nodesMainImage, locale)

    const { title: section1Title } = getDataByLocale<SectionModel>(nodesSection1, locale)
    const { title: section2Title } = getDataByLocale<SectionModel>(nodesSection2, locale)
    const { title: section3Title } = getDataByLocale<SectionModel>(nodesSection3, locale)
    const { title: section4Title } = getDataByLocale<SectionModel>(nodesSection4, locale)
    const iconWithTextEntries = mappingNode<IconWithTextModel>(nodesIconWithText, locale)

    const textWithImages = mappingEdge<TextWithImageModel>(nodesTextWithImageEdges, locale)

    const happyCustomers = getDataByLocale<HappyCustomersModel>(nodesHappyCustomers, locale)

    const [carrouselCurrentItem, setCarrouselCurrentItem] = React.useState(0)
    const handleNext = () => {
        let index: number = carrouselCurrentItem + 1
        if (index > happyCustomers.customerLogos.length - 1) {
            index = 0
        }
        setCarrouselCurrentItem(index)
    }

    const handlePrev = () => {
        let index: number = carrouselCurrentItem - 1
        if (index < 0) {
            index = happyCustomers.customerLogos.length - 1
        }
        setCarrouselCurrentItem(index)
    }

    return (
        <Page metaData={metaData} locale={locale}>
            <Section>
                <MainImage
                    imageURL={url}
                    imageURLDark={imageUrlDark}
                    title={title}
                    titleSize={titleSize}
                    textVariantTitle={textVariantTitle}
                    subtitle={subtitle}
                    subTitleSize={subTitleSize}
                    textVariantSubtitle={textVariantSubtitle}
                    textVariantSubtitleDark={textVariantSubtitleDark}
                    textPosition={textPosition}
                    buttonText={buttonText}
                    buttonURL={`/${getLanguageByLocale(locale)}/contact-us`}
                />
            </Section>
            <Section title={section1Title} id="what-we-do">
                {textWithImages.map((textWithImage) => (
                    <TextWithImage
                        key={textWithImage.title}
                        imageURL={textWithImage.image.file.url}
                        title={textWithImage.title}
                        paragraph={textWithImage.paragraph.internal.content}
                        textPosition={textWithImage.textPosition}
                    />
                ))}
            </Section>
            <Section
                title={section2Title}
                className="home_Section2"
                id="branches"
                backgroundColor={MainVariant.SECONDARY}
            >
                <div className="section2_container">
                    <Branches iconWithTextEntries={iconWithTextEntries} />
                </div>
            </Section>
            <Section className="home_section3" id="happy-customers">
                <div className="section3_container">
                    {/*<div className="section3_logos containerCarousel">
                        {happyCustomers.customerLogos.map((customerLogo, key) => (
                            <img
                                key={customerLogo.file.url}
                                src={customerLogo.file.url}
                                className={
                                    carrouselCurrentItem == key
                                        ? 'Section3__logo containerCarousel_current'
                                        : 'Section3__logo'
                                }
                            />
                        ))}
                        <ArrowLeft className="arrowLeft" onClick={handlePrev} />
                        <ArrowRight className="arrowRight" onClick={handleNext} />
                    </div>*/}
                    <CallToAction happyCustomers={happyCustomers} locale={locale} />
                </div>
            </Section>
            {!process.env.GATSBY_FLAG_DISABLE_NEWSLETTER && (
                <Section backgroundColor={MainVariant.SECONDARY}>
                    <Newsletter content={newsletterForm} locale={locale} />
                </Section>
            )}
            <Section title={section4Title} className="section_seals" id="seals">
                <a
                    rel="nofollow"
                    href="https://www.exali.de/siegel/Tuix-GmbH"
                    target="_blank"
                    title="Weiter zur IT-Haftpflicht von Tuix GmbH"
                >
                    <StaticImage
                        src="../images/siegel_de-120.png"
                        alt="Weiter zur IT-Haftpflicht von Tuix GmbH"
                    />
                </a>
            </Section>
        </Page>
    )
}

export default IndexPage
