import type { EventName } from '@lit-labs/react'
import { createComponent } from '@lit-labs/react'
import * as React from 'react'
import { TuixRadioButton } from 'tuix-webcomponents'

export const TuixRadioButtonComponent = createComponent({
    tagName: 'tuix-radio-button',
    elementClass: TuixRadioButton,
    react: React,
    events: {
        onChange: 'change' as EventName<CustomEvent>,
    },
})
