import React, { PropsWithChildren } from 'react'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import './Section.scss'
import { DarkPalette, MainVariant, Palette, TextSize, TextVariant } from 'tuix-webcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import clsx from 'clsx'

export enum TextAlign {
    CENTER = 'Center',
    LEFT = 'Left',
}

interface Props {
    title?: string
    maxWidth?: string
    textAlign?: string
    className?: string
    id?: string
    backgroundColor?: MainVariant
}

export interface SectionModel {
    title: string
}

export default ({
    title,
    children,
    maxWidth,
    textAlign,
    className,
    id,
    backgroundColor = MainVariant.PRIMARY,
}: PropsWithChildren<Props>): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const bgColor =
        backgroundColor === MainVariant.PRIMARY
            ? isDarkMode
                ? DarkPalette.BLACK
                : Palette.WHITE
            : isDarkMode
            ? DarkPalette.SECTION_BG
            : Palette.SECTION_BG

    return (
        <section style={{ backgroundColor: bgColor }}>
            <div id={id} className={`section__container ${className ?? ''}`} style={{ maxWidth }}>
                {title && (
                    <div className={clsx('section__titleContainer', `section__title${textAlign}`)}>
                        <TuixTextComponent
                            size={TextSize.S}
                            color={TextVariant.DARK_MAIN}
                            dark={isDarkMode}
                        >
                            <h2>{title}</h2>
                        </TuixTextComponent>
                    </div>
                )}
                {children}
            </div>
        </section>
    )
}
