import React from 'react'
import './IconCard.scss'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import clsx from 'clsx'

export interface Props {
    iconUrl: string
    className?: string
}

export default ({ iconUrl, className = '' }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className={clsx(className, 'iconCard_container', isDarkMode && 'dark')}>
            <img src={iconUrl} className="iconCard_icon" />
        </div>
    )
}
