import React from 'react'
import { Locale } from '../../utils/enums'
import './LogosServices.scss'

type Props = {
    imageURL: string
    customClassName?: string
}

interface File {
    url: string
}

interface Image {
    file: File
}

export interface LogoTypeModel {
    image: Image
    order: number
    node_locale: Locale
}

export default ({ imageURL }: Props): JSX.Element => {
    return (
        <div className="uxUi-logo-container">
            <img src={imageURL} className="uxUi-logo" alt={imageURL} />
        </div>
    )
}
