import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import * as React from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import './IconWithText.scss'

interface Props {
    iconUrl: string
    title: string
    textSize?: TextSize
}

interface File {
    url: string
}

interface Icon {
    file: File
}

export interface IconWithTextModel {
    text: string
    icon: Icon
    node_locale: Locale
}

export default ({ iconUrl, title, textSize = TextSize.S }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    return (
        <div className="iconWihText_container">
            <img src={iconUrl} className="iconWihText_icon" />
            <TuixTextComponent
                dark={isDarkMode}
                color={TextVariant.PRIMARY}
                size={textSize}
                className="iconWihText_text"
            >
                {title}
            </TuixTextComponent>
        </div>
    )
}
