import { useEffect, useState } from 'react'
import { ScreenSizes } from 'tuix-webcomponents'
import useWindowDimensions, { isClient } from 'utils/window'

const useDeviceWidth = () => {
    const { width } = useWindowDimensions()
    const [isTablet, setIsTablet] = useState(() => {
        return isClient ? window.innerWidth <= ScreenSizes.TABLET : false
    })

    const [isMobile, setIsMobile] = useState(() => {
        return isClient ? window.innerWidth <= ScreenSizes.MOBILE : false
    })
    const [isDesktop, setIsDesktop] = useState(() => {
        return isClient ? window.innerWidth > ScreenSizes.TABLET : false
    })

    const checkDeviceWidth = () => {
        const width = window.innerWidth
        setIsTablet(width <= ScreenSizes.TABLET)
        setIsMobile(width <= ScreenSizes.MOBILE)
        setIsDesktop(width > ScreenSizes.TABLET)
    }

    useEffect(() => {
        checkDeviceWidth()
    }, [width])

    return { isTablet, isMobile, isDesktop }
}

export default useDeviceWidth
