import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { Block } from '@contentful/rich-text-types'
import clsx from 'clsx'
import { TuixButtonComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { Link } from 'gatsby'
import React from 'react'
import { MainVariant, TextSize, TextVariant } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import { getLanguageByLocale } from 'utils/language'
import { File, Raw } from 'utils/types/common.types'
import './PortfolioTextCard.scss'

export interface PortfolioTextCardModel {
    title: string
    images: File[]
    content: Raw
    contentResume: Raw
    slug: string
    node_locale: Locale
}

export interface Props {
    portfolioEntry: PortfolioTextCardModel
    readMoreButtonText?: string
    locale: Locale
}

export default ({ portfolioEntry, readMoreButtonText, locale }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const rawContent: Block = JSON.parse(portfolioEntry.content.raw)
    const onlyParagraphs = rawContent.content.filter((content) => content.nodeType === 'paragraph')
    rawContent.content = onlyParagraphs
    const plainDescription = documentToPlainTextString(rawContent).slice(0, 250)

    return (
        <div className={clsx('paper-portfolio-entry', isDarkMode && 'dark')}>
            <div className="portfolio-entry-container">
                <img
                    src={portfolioEntry.images?.[0]?.file.url}
                    alt="main project image"
                    className="card-image"
                />
                <div className="card-description">
                    <div className="card-text">
                        <TuixTextComponent size={TextSize.M} dark={isDarkMode}>
                            {portfolioEntry.title}
                        </TuixTextComponent>
                        <TuixTextComponent
                            size={TextSize.Body1}
                            dark={isDarkMode}
                            className="short-description"
                            color={TextVariant.GREY}
                        >
                            {plainDescription}
                        </TuixTextComponent>
                    </div>
                    <Link
                        to={`/${getLanguageByLocale(locale)}/portfolio/project/${
                            portfolioEntry.slug
                        }`}
                    >
                        <TuixButtonComponent dark={isDarkMode} variant={MainVariant.SECONDARY}>
                            {readMoreButtonText}
                        </TuixButtonComponent>
                    </Link>
                </div>
            </div>
        </div>
    )
}
