import ImpressumContent from 'components/ImpressumContent'
import ImpressumTitle from 'components/ImpressumTitle'
import Page from 'components/Page'
import Section from 'components/Section'
import { MetaData } from 'components/Seo'
import { usePageContent } from 'components/hooks/usePageContent/impressum/usePageContent'
import React from 'react'
import { Asset } from 'utils/types'
import { getDataByLocale } from '../../utils/dataHelpers'
import { Locale } from '../../utils/enums'

interface Props {
    locale: Locale
}

interface ContentModel {
    title: string
    content: { raw: string }
}

export default ({ locale = Locale.de_DE }: Props): JSX.Element => {
    const { nodesContent, nodesLogoImage, nodesLogoImageDark, nodesMetaData, nodesExtraContent } =
        usePageContent()

    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)

    const {
        file: { url: logoImageURL },
    } = getDataByLocale<Asset>(nodesLogoImage, locale)
    const {
        file: { url: logoImageDarkURL },
    } = getDataByLocale<Asset>(nodesLogoImageDark, locale)

    const {
        title,
        content: { raw: richText },
    } = getDataByLocale<ContentModel>(nodesContent, locale)

    const {
        content: { raw: extraRichText },
    } = getDataByLocale<ContentModel>(nodesExtraContent, locale)

    return (
        <Page metaData={metaData} locale={locale}>
            <Section>
                <ImpressumTitle
                    iconURL={logoImageURL}
                    darkIconURL={logoImageDarkURL}
                    title={title}
                />
                <ImpressumContent
                    mainContentRichText={richText}
                    extraContentRichText={extraRichText}
                />
            </Section>
        </Page>
    )
}
