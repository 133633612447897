import MainImageSlider from 'components/MainImageSlider'
import Page from 'components/Page'
import { PortfolioTextCardModel } from 'components/PortfolioTextCard/PortfolioTextCard'
import Section from 'components/Section'
import { MetaData } from 'components/Seo'
import { graphql } from 'gatsby'
import React from 'react'
import { getDataByLocale, mappingNode } from 'utils/dataHelpers'
import { Locale } from 'utils/enums'
import { NodeQuery } from 'utils/types/common.types'
import './index.scss'
import PortfolioEntryContent from 'components/PortfolioEntryContent'
import { ContactDataModel } from 'components/PortfolioEntryContent/PortfolioEntryContent'

interface PortfolioEntryDataQueries {
    contentPortfolioEntry: NodeQuery<PortfolioTextCardModel>
    contentMetaDataPortfolio: NodeQuery<MetaData>
    contactData: NodeQuery<ContactDataModel>
    contactUsText: NodeQuery<{ text: string }>
}

type Props = {
    data: PortfolioEntryDataQueries
    pageContext: {
        slug: string
        locale: Locale
    }
}

export default ({ pageContext: { locale }, data }: Props): JSX.Element => {
    const {
        contentPortfolioEntry: {
            nodes: [portfolioEntry],
        },
        contentMetaDataPortfolio: { nodes: nodesMetaData },
        contactData: { nodes: nodesContactData },
        contactUsText: { nodes: nodesContactUsText },
    } = data
    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)
    const contactData = mappingNode(nodesContactData, locale)
    const contactUsText = getDataByLocale(nodesContactUsText, locale)

    const imagesUrls = portfolioEntry.images.map((image) => image.file.url)

    return (
        <Page metaData={metaData} locale={locale}>
            <Section id="image-slider-portfolio-entry">
                <MainImageSlider imageUrls={imagesUrls} centerMode></MainImageSlider>
            </Section>
            <Section id="content-portfolio-entry">
                <PortfolioEntryContent
                    title={portfolioEntry.title}
                    contentRaw={portfolioEntry.content.raw}
                    contentResumeRaw={portfolioEntry.contentResume?.raw || ''}
                    contactData={contactData}
                    contactUsText={contactUsText.text}
                ></PortfolioEntryContent>
            </Section>
        </Page>
    )
}

export const query = graphql`
    query QueryPortfolioEntry($slug: String!, $locale: String!) {
        contentPortfolioEntry: allContentfulPortfolioEntry(
            filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
        ) {
            nodes {
                title
                slug
                images {
                    file {
                        url
                        fileName
                    }
                }
                content {
                    raw
                }
                contentResume {
                    raw
                }
                node_locale
            }
        }

        contentMetaDataPortfolio: allContentfulSeo(
            filter: { contentful_id: { eq: "1iN5e3PNF6AsLPaB7atkEC" } }
        ) {
            nodes {
                title
                url
                description {
                    description
                }
                image {
                    file {
                        url
                    }
                }
                node_locale
            }
        }

        contactData: allContentfulContactDataFields(sort: { fields: order, order: ASC }) {
            nodes {
                text
                order
                node_locale
                iconName
            }
        }

        contactUsText: allContentfulTextButton(
            filter: { contentful_id: { eq: "1bnuvPyb0a1DhPXThdGH7t" } }
        ) {
            nodes {
                node_locale
                text
            }
        }
    }
`
