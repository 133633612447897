import clsx from 'clsx'
import Paginator from 'components/Paginator'
import PortfolioTextCard, {
    PortfolioTextCardModel,
} from 'components/PortfolioTextCard/PortfolioTextCard'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import './PortfolioEntries.scss'

export interface Props {
    buttonCardText: string
    currentPage: number
    entriesByPage: number
    locale: Locale
    pathPrefix: string
    portfolioEntries: PortfolioTextCardModel[]
    title: string
    totalEntries: number
}

export default ({
    buttonCardText,
    currentPage,
    entriesByPage,
    locale,
    pathPrefix,
    portfolioEntries,
    title,
    totalEntries,
}: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const [current, setCurrent] = useState(currentPage)
    const isNotFullPage = portfolioEntries.length < entriesByPage

    const onChangePage = (pageIndex) => {
        setCurrent(pageIndex)
        const urlIndex = pageIndex > 1 ? pageIndex : ''
        navigate(`/${pathPrefix}/${urlIndex}`)
    }

    return (
        <div className="portfolio-entries-container">
            <TuixTextComponent
                dark={isDarkMode}
                size={TextSize.L}
                color={TextVariant.MAIN}
                className="our-work-title"
            >
                {title}
            </TuixTextComponent>
            <div className={clsx('entry-cards-container', isNotFullPage && 'to-left')}>
                {portfolioEntries.map((portfolioEntry, index) => (
                    <PortfolioTextCard
                        portfolioEntry={portfolioEntry}
                        readMoreButtonText={buttonCardText}
                        locale={locale}
                        key={index}
                    ></PortfolioTextCard>
                ))}
            </div>
            <Paginator
                current={current}
                total={totalEntries}
                pageSize={entriesByPage}
                className="portfolio-pagination"
                onChange={onChangePage}
            ></Paginator>
        </div>
    )
}
