import type { EventName } from '@lit-labs/react'

import * as React from 'react'
import { createComponent } from '@lit-labs/react'
import { TuixButton } from 'tuix-webcomponents'

export const TuixButtonComponent = createComponent({
    tagName: 'tuix-button',
    elementClass: TuixButton,
    react: React,
    events: {
        onClick: 'customClick' as EventName<CustomEvent>,
    },
})
