import * as React from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import './BlogTitle.scss'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'

export type BlogTitleProps = {
    title: string
}

export default ({ title }: BlogTitleProps): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isMobile } = useDeviceWidth()

    return (
        <h1 className="blog_titleContainer">
            <TuixTextComponent
                size={isMobile ? TextSize.XL : TextSize.L}
                dark={isDarkMode}
                color={isDarkMode ? TextVariant.MAIN : TextVariant.DARK_MAIN}
            >
                {title}
            </TuixTextComponent>
        </h1>
    )
}
