import type { EventName } from '@lit-labs/react'
import { createComponent } from '@lit-labs/react'
import * as React from 'react'
import { TuixToggleButton } from 'tuix-webcomponents'

export const TuixToggleButtonComponent = createComponent({
    tagName: 'tuix-toggle-button',
    elementClass: TuixToggleButton,
    react: React,
    events: {
        onChange: 'change' as EventName<CustomEvent>,
    },
})
