import React from 'react'
import './CallToAction.scss'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { TuixButtonComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Link } from 'gatsby'
import { getLanguageByLocale } from 'utils/language'
import { HappyCustomersModel } from 'pages/index'
import { Locale } from 'utils/enums'

interface Props {
    happyCustomers: HappyCustomersModel
    locale: Locale
}

export default ({ happyCustomers, locale }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const titlePieces = happyCustomers.title.split('|')

    return (
        <div className="section3_callToAction">
            <TuixTextComponent size={TextSize.XL} color={TextVariant.PRIMARY} dark={isDarkMode}>
                {titlePieces.map((piece, index) => (
                    <div key={index}>
                        {piece}
                        <br />
                    </div>
                ))}
            </TuixTextComponent>
            <Link to={`/${getLanguageByLocale(locale)}/contact-us`}>
                <TuixButtonComponent dark={isDarkMode}>
                    {happyCustomers.buttonText}
                </TuixButtonComponent>
            </Link>
        </div>
    )
}
