import React, { createContext, useContext, useEffect, useState } from 'react'
import tuixLocalStorage from 'utils/tuixLocalStorage'

const DarkModeContext = createContext({
    isDarkMode: false,
    enableDarkMode: null,
    disableDarkMode: null,
})

export function DarkModeProvider({ children }): JSX.Element {
    const [isDarkMode, setIsDarkMode] = useState(false)

    const [isDarkModeStorage, setIsDarkModeStorage] = useState(() => {
        const storedDarkMode = tuixLocalStorage.getItem<string>('theme')
        return storedDarkMode === 'dark'
    })

    const enableDarkMode = () => {
        if (!isDarkModeStorage) {
            setIsDarkModeStorage(true)
            tuixLocalStorage.setItem('theme', 'dark')
        }
    }

    const disableDarkMode = () => {
        if (isDarkModeStorage) {
            setIsDarkModeStorage(false)
            tuixLocalStorage.setItem('theme', 'light')
        }
    }

    useEffect(() => {
        const storedDarkMode = tuixLocalStorage.getItem('theme')
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
        if (storedDarkMode === null && darkModeMediaQuery.matches) enableDarkMode()

        const updateDarkMode = (e) => {
            setIsDarkModeStorage(e.matches)
        }

        darkModeMediaQuery.addEventListener('change', updateDarkMode)

        return () => {
            darkModeMediaQuery.removeEventListener('change', updateDarkMode)
        }
    }, [])

    useEffect(() => {
        setIsDarkMode(isDarkModeStorage)
    }, [isDarkModeStorage])

    return (
        <DarkModeContext.Provider value={{ isDarkMode, enableDarkMode, disableDarkMode }}>
            {children}
        </DarkModeContext.Provider>
    )
}

export function useDarkModeContext() {
    return useContext(DarkModeContext)
}
