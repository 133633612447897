export enum Locale {
    de_DE = 'de-DE',
    en_US = 'en-US',
}

export enum EmailTemplate {
    CONTACT = 'template_n87v86x',
    CV = 'template_m9lb2lm',
    NEWSLETTER_EN = 'template_048fcvp',
    NEWSLETTER_DE = 'template_21vpyoh',
}
