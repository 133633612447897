import React from 'react'
import './TextWithImage.scss'
import { Locale } from '../../utils/enums'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'

type Props = {
    title: string
    paragraph: string
    imageURL: string
    textPosition: 'left' | 'right'
}

interface File {
    url: string
}

interface Image {
    file: File
}

interface Internal {
    content: string
}

interface Paragraph {
    internal: Internal
}

export interface TextWithImageModel {
    title: string
    textPosition: 'left' | 'right'
    image: Image
    paragraph: Paragraph
    node_locale: Locale
}

export default ({ title, paragraph, imageURL, textPosition }: Props): JSX.Element => {
    const directionClass =
        textPosition === 'left' ? 'text-with-image_direction' : 'text-with-image_direction-reverse'

    const { isDarkMode } = useDarkModeContext()

    return (
        <div className={`text-with-image_container ${directionClass}`}>
            <div className="division_container">
                <div className="text-with-image_text-container">
                    <TuixTextComponent
                        size={TextSize.M}
                        color={TextVariant.PRIMARY}
                        dark={isDarkMode}
                    >
                        <h3>{title}</h3>
                    </TuixTextComponent>
                    <TuixTextComponent
                        size={TextSize.Body1}
                        color={TextVariant.PRIMARY}
                        dark={isDarkMode}
                    >
                        <p>{paragraph}</p>
                    </TuixTextComponent>
                </div>
            </div>
            <div className="division_container">
                <div className="text-with-image_img">
                    <img src={imageURL} alt={`${title} visual representation`} />
                </div>
            </div>
        </div>
    )
}
