import Page from 'components/Page'
import { Locale } from '../../utils/enums'
import Section from 'components/Section'
import React from 'react'
import MainImage from 'components/MainImage'
import { getDataByLocale, mappingEdge } from '../../utils/dataHelpers'
import './index.scss'
import PositionCard from 'components/PositionCard'
import { MainImageModel } from 'components/MainImage/MainImage'
import { SectionModel } from 'components/Section/Section'
import { BenefitCardModel } from 'components/BenefitCard/BenefitCard'
import { PositionCardModel } from 'components/PositionCard/PositionCard'
import { usePageContent } from 'components/hooks/usePageContent/career/usePageContent'
import { MetaData } from 'components/Seo'
import { MainVariant } from 'tuix-webcomponents'
import Benefits from 'components/Benefits/Benefits'

interface Props {
    locale: Locale
}

export default ({ locale = Locale.de_DE }: Props): JSX.Element => {
    const {
        nodesMainImage,
        nodesSection1,
        nodesSection2,
        nodesBenefitsCardsEdges,
        nodesPositionCardEdges,
        nodesMetaData,
    } = usePageContent()

    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)

    const {
        backgroundImage: {
            file: { url },
        },
        backgroundImageDark: {
            file: { url: imageUrlDark },
        },
        title,
        textVariantTitle,
        subtitle,
        textVariantSubtitle,
        textPosition,
        buttonText,
    } = getDataByLocale<MainImageModel>(nodesMainImage, locale)

    const { title: section1Title } = getDataByLocale<SectionModel>(nodesSection1, locale)
    const { title: section2Title } = getDataByLocale<SectionModel>(nodesSection2, locale)
    const benefitsCardsEdges = mappingEdge<BenefitCardModel>(nodesBenefitsCardsEdges, locale)
    const positionCardEdges = mappingEdge<PositionCardModel>(nodesPositionCardEdges, locale)

    return (
        <Page metaData={metaData} locale={locale}>
            <Section>
                <MainImage
                    imageURL={url}
                    imageURLDark={imageUrlDark}
                    title={title}
                    textVariantTitle={textVariantTitle}
                    subtitle={subtitle}
                    textVariantSubtitle={textVariantSubtitle}
                    textPosition={textPosition}
                    buttonText={buttonText}
                />
            </Section>
            <Section title={section1Title} id="benefits">
                <Benefits cardsEdges={benefitsCardsEdges} />
            </Section>
            <Section
                title={section2Title}
                className="careerSection"
                id="positions"
                backgroundColor={MainVariant.SECONDARY}
            >
                <div className="positionCardsContainer">
                    {positionCardEdges.map((positionCard) => (
                        <PositionCard
                            key={positionCard.title}
                            title={positionCard.title}
                            time={positionCard.time}
                            apply={positionCard.apply}
                            slug={positionCard.slug}
                            locale={positionCard.node_locale}
                        />
                    ))}
                </div>
            </Section>
        </Page>
    )
}
