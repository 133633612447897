import React from 'react'
import { TextVariant, IconName, IconSizeVariant } from 'tuix-webcomponents'
import { TuixIconComponent } from '../../components/TuixWebcomponents/TuixIcon.wrapped'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import Logotype from 'components/Logotype'
import { LogoTypeModel } from 'components/Logotype/Logotype'
import './LogotypeContainer.scss'
import Carousel from 'react-multi-carousel'
import { ResponsiveType } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { clsx } from 'clsx'

type Props = {
    logos: readonly LogoTypeModel[]
}
const responsive: ResponsiveType = {
    mobile: {
        breakpoint: { max: 640, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
}
export default ({ logos }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isMobile } = useDeviceWidth()

    return !isMobile ? (
        <div className="containerLogotypes">
            {logos.map((logotype) => (
                <Logotype
                    key={logotype.name}
                    name={logotype.name}
                    imageURL={logotype.image.file.url}
                    customClassName={clsx(
                        `logo${
                            logotype.name[0].toUpperCase() + logotype.name.slice(1).toLowerCase()
                        }`,
                    )}
                />
            ))}
        </div>
    ) : (
        <Carousel
            responsive={responsive}
            infinite={true}
            containerClass="carouselContainer"
            customLeftArrow={
                <TuixIconComponent
                    size={IconSizeVariant.MEDIUM}
                    name={IconName.CHEVRON_LEFT}
                    color={TextVariant.MAIN}
                    dark={isDarkMode}
                    className="arrowLeft"
                />
            }
            customRightArrow={
                <TuixIconComponent
                    size={IconSizeVariant.MEDIUM}
                    name={IconName.CHEVRON_RIGHT}
                    color={TextVariant.MAIN}
                    className="arrowRight"
                    dark={isDarkMode}
                />
            }
        >
            {logos.map((logotype) => (
                <Logotype
                    key={logotype.name}
                    name={logotype.name}
                    imageURL={logotype.image.file.url}
                    customClassName={clsx(
                        `logo${
                            logotype.name[0].toUpperCase() + logotype.name.slice(1).toLowerCase()
                        }`,
                    )}
                />
            ))}
        </Carousel>
    )
}
