import { TuixTextComponent } from 'components/TuixWebcomponents'
import * as React from 'react'
import { TextSize } from 'tuix-webcomponents'
import './RichTuixCodeBlock.scss'
import { CodeBlock, atomOneDark } from 'react-code-blocks'
import {
    CodeBlockSupportedLanguages,
    getInternalContent,
    isSupportedLanguage,
} from 'utils/richTextHelpers'

interface RichTextNode {
    content: Array<RichContent>
}
interface RichContent {
    value: string
}
type Props = {
    node: RichTextNode
}

export default ({ node }: Props): JSX.Element => {
    const { content, name } = getInternalContent(node.content[0].value)
    const language = isSupportedLanguage(name) ? name : CodeBlockSupportedLanguages.BASH
    return (
        <>
            <div className="rich-tuix-codeblock">
                <CodeBlock
                    text={content}
                    language={language}
                    showLineNumbers={false}
                    theme={atomOneDark}
                    wrapLongLines={false}
                />
            </div>
            <br />
        </>
    )
}
