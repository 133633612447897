import { clsx } from 'clsx'
import ImageSwitcher from 'components/ImageSwitcher'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import React from 'react'
import { TextSize } from 'tuix-webcomponents'
import { Locale } from 'utils/enums'
import './UxUi.scss'

type UxUiSection = {
    description: string
    locale: Locale
    uxuiProcessImage: string
    uxuiProcessImageDark: string
    uxUiProcessImageTablet: string
    uxUiProcessImageTabletDark: string
    uxUiProcessImageMobile: string
    uxUiProcessImageMobileDark: string
}

interface UxUiImage {
    light: string
    dark: string
}

export default ({
    description,
    locale,
    uxuiProcessImage,
    uxuiProcessImageDark,
    uxUiProcessImageTablet,
    uxUiProcessImageTabletDark,
    uxUiProcessImageMobile,
    uxUiProcessImageMobileDark,
}: UxUiSection): JSX.Element => {
    const { isTablet, isMobile } = useDeviceWidth()
    const { isDarkMode } = useDarkModeContext()

    const uxUiImage: UxUiImage = {
        light: isMobile
            ? uxUiProcessImageMobile
            : isTablet
            ? uxUiProcessImageTablet
            : uxuiProcessImage,
        dark: isMobile
            ? uxUiProcessImageMobileDark
            : isTablet
            ? uxUiProcessImageTabletDark
            : uxuiProcessImageDark,
    }

    return (
        <div className="uxUi-container">
            <div className="uxUi-paragraph-container">
                <TuixTextComponent size={TextSize.Body1} dark={isDarkMode}>
                    <p>{description}</p>
                </TuixTextComponent>
            </div>
            <div className="uxUi-image-container">
                <ImageSwitcher
                    lightImageURL={uxUiImage.light}
                    darkImageURL={uxUiImage.dark}
                    alt="UxUi-process"
                    className={clsx('uxUi-image', `uxUi-image-${locale}`)}
                ></ImageSwitcher>
            </div>
        </div>
    )
}
