import React from 'react'
import { BenefitCardModel } from 'components/BenefitCard/BenefitCard'
import { Node } from 'utils/dataHelpers'
import BenefitCard from 'components/BenefitCard'
import './Benefits.scss'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { clsx } from 'clsx'
export interface BenefitsModel {
    cardsEdges: readonly Node<BenefitCardModel>[]
}

export default ({ cardsEdges }: BenefitsModel): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div>
            <div className={clsx('section__border_line', isDarkMode && 'dark')} />
            <div className={clsx('benefitsAndBorderLineCards_container', isDarkMode && 'dark')}>
                <div className="benefitsCards_container">
                    <div className={clsx('border_line', isDarkMode && 'dark')}></div>
                    {cardsEdges.map((benefitCard) => (
                        <BenefitCard
                            key={benefitCard.text}
                            text={benefitCard.text}
                            image={benefitCard.image.file.url}
                            textSide={benefitCard.textSide}
                            color={benefitCard.color}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
