import type { EventName } from '@lit-labs/react'
import { createComponent } from '@lit-labs/react'
import * as React from 'react'
import { TuixCheckbox } from 'tuix-webcomponents'

export const TuixCheckboxComponent = createComponent({
    tagName: 'tuix-checkbox',
    elementClass: TuixCheckbox,
    react: React,
    events: {
        onChange: 'change' as EventName<CustomEvent>,
    },
})
