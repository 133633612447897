import clsx from 'clsx'
import IconWithText from 'components/IconWithText'
import { IconWithTextModel } from 'components/IconWithText/IconWithText'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import * as React from 'react'
import { TextSize } from 'tuix-webcomponents'
import './Branches.scss'
import Dots from './images/branches-dots.svg'

interface Props {
    iconWithTextEntries: readonly IconWithTextModel[]
}

export default ({ iconWithTextEntries }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className={clsx('branches__container', isDarkMode && 'dark')}>
            {iconWithTextEntries.map((iconWithTextEntry) => (
                <div className="branches_iconContainer" key={iconWithTextEntry.text}>
                    <Dots className="branches_dots" />
                    <IconWithText
                        iconUrl={iconWithTextEntry.icon.file.url}
                        title={iconWithTextEntry.text}
                        textSize={TextSize.S}
                    />
                </div>
            ))}
        </div>
    )
}
