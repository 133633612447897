import React from 'react'
import { Helmet } from 'react-helmet'
import { MetaData } from './types'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'

interface Props {
    data: MetaData
}

export default ({ data }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const iconUrlPrefix =
        process.env.GATSBY_S3_BUCKET_URL + `/Tuix/Image/${isDarkMode ? 'dark_' : ''}icon`
    const faviconUrlPrefix =
        process.env.GATSBY_S3_BUCKET_URL + `/Tuix/Image/${isDarkMode ? 'dark_' : ''}favicon`
    if (process.env.GATSBY_FLAG_ENABLE_SEO) {
        return (
            <Helmet title={data?.title}>
                <link
                    href=" http://fonts.googleapis.com/css?family=Droid+Sans"
                    rel="stylesheet"
                    type="text/css"
                ></link>
                <link rel="icon" href={`${faviconUrlPrefix}.ico`} />
                <link rel="icon" href={`${iconUrlPrefix}-140.png`} sizes="140x140" />
                <link rel="icon" href={`${iconUrlPrefix}-180.png`} sizes="180x180" />
                <link rel="icon" href={`${iconUrlPrefix}-192.png`} sizes="192x192" />
                <link rel="icon" href={`${iconUrlPrefix}-512.png`} sizes="512x512" />
                {data?.description?.description && (
                    <meta name="description" content={data?.description?.description} />
                )}
                {data?.image?.file?.url && <meta name="image" content={data?.image?.file?.url} />}
                {data?.url && <meta property="og:url" content={data?.url} />}
                {data?.title && <meta property="og:title" content={data?.title} />}
                {data?.description?.description && (
                    <meta property="og:description" content={data?.description?.description} />
                )}
                {data?.image?.file?.url && (
                    <meta property="og:image" content={data?.image?.file?.url} />
                )}
            </Helmet>
        )
    }
    return (
        <Helmet>
            <link
                href=" http://fonts.googleapis.com/css?family=Droid+Sans"
                rel="stylesheet"
                type="text/css"
            ></link>
            <meta name="robots" content="noindex" />
            <meta name="googlebot" content="noindex" />
            <link rel="icon" href={`${faviconUrlPrefix}.ico`} />
            <link rel="icon" href={`${iconUrlPrefix}-140.png`} sizes="140x140" />
            <link rel="icon" href={`${iconUrlPrefix}-180.png`} sizes="180x180" />
            <link rel="icon" href={`${iconUrlPrefix}-192.png`} sizes="192x192" />
            <link rel="icon" href={`${iconUrlPrefix}-512.png`} sizes="512x512" />
        </Helmet>
    )
}
