import ImageSwitcher from 'components/ImageSwitcher'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React from 'react'
import { TextSize } from 'tuix-webcomponents'
import { Locale } from '../../utils/enums'
import './ImageWithText.scss'

type Props = {
    title: string
    paragraph: string
    imageURL: string
    imageURLDark: string
}

interface File {
    url: string
}

interface Image {
    file: File
}

interface Internal {
    content: string
}

interface Paragraph {
    internal: Internal
}

export interface ImageWithTextModel {
    title: string
    image: Image
    imageDark: Image
    paragraph: Paragraph
    order: number
    node_locale: Locale
}

export default ({ title, paragraph, imageURL, imageURLDark }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className="imageWithText_container">
            <ImageSwitcher
                lightImageURL={imageURL}
                darkImageURL={imageURLDark}
                className="imageWithText_img"
                alt="great culture icon"
            ></ImageSwitcher>
            <TuixTextComponent size={TextSize.S} className="imageWithText_title" dark={isDarkMode}>
                <h3>{title}</h3>
            </TuixTextComponent>
            <TuixTextComponent
                size={TextSize.Body1}
                className="imageWithText_paragraph"
                dark={isDarkMode}
            >
                <p>{paragraph}</p>
            </TuixTextComponent>
        </div>
    )
}
