import Footer from 'components/Footer'
import { FooterButtonText, FooterOptionModel } from 'components/Footer/Footer'
import Navbar from 'components/Navbar'
import { NavbarModel } from 'components/Navbar/Navbar'
import { DarkModeProvider } from 'components/hooks/darkMode/useDarkModeContext'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { pathsToIgnoreExp } from '../../utils/build/pathsConfig'
import { getDataByLocale, mappingEdge } from '../../utils/dataHelpers'
import { Locale } from '../../utils/enums'
import Seo, { MetaData } from '../Seo'
import './Page.scss'

type Props = {
    children?: JSX.Element | JSX.Element[]
    locale?: Locale
    metaData?: MetaData
    isSinglePage?: boolean
}

export default ({ locale, children, metaData, isSinglePage }: Props): JSX.Element => {
    if (isSinglePage) {
        return (
            <DarkModeProvider>
                <div className="page">
                    <div className="pageContainer">
                        <main>{children}</main>
                    </div>
                </div>
            </DarkModeProvider>
        )
    }
    const {
        allContentfulNavbar: { nodes: nodesNavBar },
        allContentfulFooterOption: { edges: nodesFooter },
        textButton1: { nodes: nodesTextButton1 },
    } = useStaticQuery(graphql`
        query Query {
            allContentfulNavbar {
                nodes {
                    node_locale
                    logo {
                        file {
                            url
                        }
                    }
                    darkLogo {
                        file {
                            url
                        }
                    }
                    contactUs
                    links {
                        path
                        text
                    }
                    languageLabel
                    theme
                }
            }
            allContentfulFooterOption(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        title
                        order
                        node_locale
                        items
                        link
                        footerItem {
                            anchor
                            text
                            redirect
                        }
                    }
                }
            }
            textButton1: allContentfulTextButton(
                filter: { contentful_id: { eq: "4K8OgIcs8MJejk7pcQtvVI" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    text
                }
            }
        }
    `)

    const {
        logo: {
            file: { url: logoUrl },
        },
        darkLogo: {
            file: { url: darkLogoUrl },
        },
        languageLabel,
        theme,
        contactUs,
        links,
    } = getDataByLocale<NavbarModel>(nodesNavBar, locale)

    const footerOptions = mappingEdge<FooterOptionModel>(nodesFooter, locale)

    const { text: textButton1 } = getDataByLocale<FooterButtonText>(nodesTextButton1, locale)

    const filteredLinks = links.filter((link) => !pathsToIgnoreExp.test(link.path))

    return (
        <DarkModeProvider>
            <div className="page">
                <div className="pageContainer">
                    {metaData && <Seo data={metaData} />}
                    <Navbar
                        darkLogoUrl={darkLogoUrl}
                        logoUrl={logoUrl}
                        contactUs={contactUs}
                        links={filteredLinks}
                        locale={locale}
                        languageLabel={languageLabel}
                        theme={theme}
                    />
                    <main>{children}</main>
                    <Footer options={footerOptions} button={textButton1} locale={locale} />
                </div>
            </div>
        </DarkModeProvider>
    )
}
