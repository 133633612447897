import React, { useState } from 'react'
import './NewsletterForm.scss'
import { NewsletterProps } from '../Newsletter'
import { Locale } from 'utils/enums'
import Loading from 'components/Loading'
import {
    TuixButtonComponent,
    TuixCheckboxComponent,
    TuixInputComponent,
    TuixTextComponent,
} from 'components/TuixWebcomponents'
import {
    CheckboxLabelSide,
    DescriptionType,
    InputType,
    MainVariant,
    TextSize,
    TextVariant,
} from 'tuix-webcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import tuixClientApi from 'utils/tuixClientApi'
interface CommonFieldValues<T> {
    value: T
    isValid: boolean
}

interface FieldsState {
    email: CommonFieldValues<string | undefined>
}

type Props = {
    content: NewsletterProps
    locale: Locale
    onError: () => void
    onClick: () => void
}

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export default ({ content, locale, onError, onClick }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isTablet, isMobile } = useDeviceWidth()
    const [formData, setFormData] = useState<FieldsState>()
    const [isChecked, setChecked] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const isValidFormData = (data: FieldsState) => {
        if (!data) return false
        return Object.values(data).every((field) => field.isValid)
    }

    const sendForm = async () => {
        if (!isValidFormData(formData)) return

        try {
            setLoading(true)
            await tuixClientApi.createSubscription({
                createSubscriptionDTO: {
                    email: formData.email.value,
                    language: locale,
                },
            })
        } catch (error) {
            onError()
        }

        onClick()
        setLoading(false)
    }

    const onEmailInput = (event) => {
        const newEmail = event.detail.value as string
        const updatedFormData: FieldsState = {
            ...formData,
            email: {
                value: newEmail,
                isValid: !!EMAIL_REGEX.test(newEmail),
            },
        }
        setFormData(updatedFormData)
        isValidFormData(updatedFormData)
    }

    return (
        <div className="newsletter-form-container">
            <div className="text-container">
                <div className="icon-title-containter">
                    <img
                        src={
                            isDarkMode ? content.darkEmailIcon.file.url : content.mailIcon.file.url
                        }
                        alt="mail icon"
                    />
                    <TuixTextComponent
                        size={TextSize.XL}
                        dark={isDarkMode}
                        color={TextVariant.PRIMARY}
                    >
                        {content.title.split('|').map((piece, index) => (
                            <div key={index}>
                                {piece}
                                {isMobile ? <br /> : isTablet ? ' ' : <br />}
                            </div>
                        ))}
                    </TuixTextComponent>
                </div>
                <TuixTextComponent
                    size={TextSize.S}
                    dark={isDarkMode}
                    color={isDarkMode ? TextVariant.LIGHT_GREY : TextVariant.GREY}
                >
                    {content.descriptionText.split('|').map((piece, index) => (
                        <div key={index}>
                            {piece}
                            <br />
                        </div>
                    ))}
                </TuixTextComponent>
            </div>
            <div className="form-container_right-side">
                <form className="form-container">
                    <div className="form-container_fields">
                        <TuixInputComponent
                            title={content.placeholderText}
                            type={InputType.TEXT}
                            dark={isDarkMode}
                            onBlur={onEmailInput}
                            description={content.invalidEmailMessage}
                            descriptionType={
                                !formData?.email?.value
                                    ? null
                                    : !formData?.email?.isValid
                                    ? DescriptionType.ERROR
                                    : null
                            }
                        />
                        <TuixCheckboxComponent
                            checked={isChecked}
                            dark={isDarkMode}
                            label={content.checkboxText}
                            labelSide={CheckboxLabelSide.RIGHT}
                            onChange={() => setChecked(!isChecked)}
                        >
                            <TuixTextComponent
                                size={TextSize.Field1}
                                color={isDarkMode ? TextVariant.PRIMARY : TextVariant.GREY}
                                dark={isDarkMode}
                            >
                                {content.checkboxText}
                            </TuixTextComponent>
                        </TuixCheckboxComponent>
                    </div>
                    <div className="form-button-container">
                        <TuixButtonComponent
                            dark={isDarkMode}
                            variant={MainVariant.SECONDARY}
                            disabled={!isChecked || isLoading || !isValidFormData(formData)}
                            onClick={sendForm}
                        >
                            {content.buttonText}
                        </TuixButtonComponent>
                        {isLoading && (
                            <div className="form-button-loading">
                                <Loading />
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
