import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { findNodes } from 'utils/richTextHelpers'
import { TextSize } from 'tuix-webcomponents'
import * as React from 'react'
import RichTuixTextBlog from './RichTuixTextBlog'
import RichTuixTextList from './RichTuixTextList'
import { TAG } from './RichTuixTextBlog/RichTuixTextBlog'
import { TuixLinkComponent } from 'components/TuixWebcomponents'
import RichTuixCodeBlock from './RichTuixCodeBlock'

export const TUIX_RICH_RENDER_OPTIONS_BLOG = {
    renderText: (text) => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
    renderMark: {
        [MARKS.CODE]: (text) => {
            if (text.length > 0) {
                return <code>{text}</code>
            }
        },
    },
    renderNode: {
        [INLINES.HYPERLINK]: ({ data }, children: JSX.Element) => {
            return <TuixLinkComponent href={data.uri}>{children}</TuixLinkComponent>
        },
        [BLOCKS.PARAGRAPH]: (node, children: JSX.Element) => {
            if (
                node.content.length === 1 &&
                findNodes(node.content[0].marks, (mark) => mark.type === 'code')
            ) {
                return <RichTuixCodeBlock node={node}></RichTuixCodeBlock>
            }
            return (
                <RichTuixTextBlog tag={TAG.P} size={TextSize.Body1}>
                    {children}
                </RichTuixTextBlog>
            )
        },
        [BLOCKS.HEADING_1]: (node, children: JSX.Element) => (
            <RichTuixTextBlog tag={TAG.H1} size={TextSize.S}>
                {children}
            </RichTuixTextBlog>
        ),
        [BLOCKS.HEADING_2]: (node, children: JSX.Element) => (
            <RichTuixTextBlog tag={TAG.H2} size={TextSize.S}>
                {children}
            </RichTuixTextBlog>
        ),
        [BLOCKS.HEADING_3]: (node, children: JSX.Element) => (
            <RichTuixTextBlog tag={TAG.H3} size={TextSize.S}>
                {children}
            </RichTuixTextBlog>
        ),
        [BLOCKS.HEADING_4]: (node, children: JSX.Element) => (
            <RichTuixTextBlog tag={TAG.H4} size={TextSize.XS}>
                {children}
            </RichTuixTextBlog>
        ),
        [BLOCKS.HEADING_5]: (node, children: JSX.Element) => (
            <RichTuixTextBlog tag={TAG.H5} size={TextSize.XS}>
                {children}
            </RichTuixTextBlog>
        ),
        [BLOCKS.HEADING_6]: (node, children: JSX.Element) => (
            <RichTuixTextBlog tag={TAG.H6} size={TextSize.XS}>
                {children}
            </RichTuixTextBlog>
        ),
        [BLOCKS.UL_LIST]: (node, children: JSX.Element) => (
            <RichTuixTextList>{children}</RichTuixTextList>
        ),
    },
}
