import clsx from 'clsx'
import ImageSwitcher from 'components/ImageSwitcher'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import React from 'react'
import { TextSize } from 'tuix-webcomponents'
import { InfoCardOrientation } from './InfoCard.enum'
import './InfoCard.scss'

export interface Props {
    text: string
    iconUrl: string
    iconUrlDark?: string
    orientation: InfoCardOrientation
}

export default ({ text, iconUrl, orientation, iconUrlDark }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const directionClass =
        orientation === InfoCardOrientation.RIGHT
            ? 'infoCard_directionReverse'
            : 'infoCard_direction'
    const { isTablet } = useDeviceWidth()

    return (
        <div className={clsx('infoCard_mainContainer', isDarkMode && 'dark')}>
            <div className={`infoCard_container ${directionClass}`}>
                <TuixTextComponent
                    size={isTablet ? TextSize.S : TextSize.M}
                    className="infoCard_title"
                    dark={isDarkMode}
                >
                    <h3>{text}</h3>
                </TuixTextComponent>
                <div className="infoCard_background_wrapper">
                    <div className={`infoCard_background ${directionClass}`}>
                        <ImageSwitcher
                            lightImageURL={iconUrl}
                            darkImageURL={iconUrlDark || iconUrl}
                            className="infoCard_img"
                        ></ImageSwitcher>
                    </div>
                </div>
                <div className="line_container">
                    <div className="dotted_line" />
                </div>
            </div>
        </div>
    )
}
