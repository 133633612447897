import clsx from 'clsx'
import { TuixIconComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import Pagination, { PaginationProps } from 'rc-pagination'
import React from 'react'
import { IconName, IconSizeVariant, TextVariant } from 'tuix-webcomponents'
import './Paginator.scss'

type Props = PaginationProps

interface ItemRenderProps {
    element: React.ReactNode
    isDarkMode: boolean
    pageIndex: number
    type: string
}

const DAFAULT_PAGE_SIZE = 10

const itemRender = ({ pageIndex, element, isDarkMode, type }: ItemRenderProps) => {
    if (type === 'page') {
        return <div className={clsx('page-number', isDarkMode && 'dark')}>{pageIndex}</div>
    }
    if (type === 'prev') {
        return (
            <TuixIconComponent
                size={IconSizeVariant.MEDIUM}
                color={TextVariant.PRIMARY}
                dark={isDarkMode}
                name={IconName.CHEVRON_LEFT}
            ></TuixIconComponent>
        )
    }
    if (type === 'next') {
        return (
            <TuixIconComponent
                size={IconSizeVariant.MEDIUM}
                color={TextVariant.PRIMARY}
                dark={isDarkMode}
                name={IconName.CHEVRON_RIGHT}
            ></TuixIconComponent>
        )
    }
    if (type === 'jump-prev' || type === 'jump-next') {
        return <div className={clsx('page-number', isDarkMode && 'dark')}>...</div>
    }
    return element
}

export default ({ className, pageSize, ...rest }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className={clsx('paginator-main-container', className)}>
            <Pagination
                itemRender={(pageIndex, type, element) =>
                    itemRender({
                        pageIndex,
                        type,
                        element,
                        isDarkMode,
                    })
                }
                pageSize={pageSize || DAFAULT_PAGE_SIZE}
                {...rest}
            ></Pagination>
        </div>
    )
}
