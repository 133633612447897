import React from 'react'
import InfoCard, { InfoCardOrientation } from 'components/InfoCard'
import BulletPoints from 'components/BulletPoints'
import './InfoCardBulletPoints.scss'
import { Locale } from 'utils/enums'
import { InfoCardBulletPointsSide } from './InfoCardBulletPoints.enums'

interface IBulletPointsEntries {
    texts: readonly string[]
    node_locale: Locale
}

export interface Props {
    infoCardEntries: IInfoCard
    bulletPointTexts: readonly string[]
    textSide: InfoCardBulletPointsSide
}

interface File {
    url: string
}

interface IconUrl {
    file: File
}

interface IInfoCard {
    text: string
    iconUrl: IconUrl
    iconUrlDark?: IconUrl
    orientation: InfoCardOrientation
    node_locale: Locale
}

export interface InfoCardBulletPointsModel {
    infoCard: IInfoCard
    bulletPoint: IBulletPointsEntries
    order: number
    textSide: InfoCardBulletPointsSide
    node_locale: Locale
}

export default ({ infoCardEntries, bulletPointTexts, textSide }: Props): JSX.Element => {
    const directionClass =
        textSide === InfoCardBulletPointsSide.LEFT
            ? 'infoCardBulletPoints_row'
            : 'infoCardBulletPoints_row-reverse'

    return (
        <div className={`infoCardBulletPoints ${directionClass}`}>
            <InfoCard
                text={infoCardEntries.text}
                iconUrl={infoCardEntries.iconUrl.file.url}
                orientation={infoCardEntries.orientation}
                iconUrlDark={infoCardEntries.iconUrlDark?.file.url}
            />
            <div className={'bulletPoints_container'}>
                <BulletPoints texts={bulletPointTexts} />
            </div>
        </div>
    )
}
