import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import * as React from 'react'
import { TextSize } from 'tuix-webcomponents'
import RichTuixText from './RichTuixText'
import RichTuixTextList from './RichTuixTextList'

export const TUIX_RICH_RENDER_OPTIONS: Options = {
    renderText: (text) => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children: JSX.Element) => (
            <RichTuixText size={TextSize.Body1}>{children}</RichTuixText>
        ),
        [BLOCKS.HEADING_1]: (node, children: JSX.Element) => (
            <RichTuixText size={TextSize.XL}>{children}</RichTuixText>
        ),
        [BLOCKS.HEADING_2]: (node, children: JSX.Element) => (
            <RichTuixText size={TextSize.L}>{children}</RichTuixText>
        ),
        [BLOCKS.HEADING_3]: (node, children: JSX.Element) => (
            <RichTuixText size={TextSize.M}>{children}</RichTuixText>
        ),
        [BLOCKS.HEADING_4]: (node, children: JSX.Element) => (
            <RichTuixText size={TextSize.S}>{children}</RichTuixText>
        ),
        [BLOCKS.HEADING_5]: (node, children: JSX.Element) => (
            <RichTuixText size={TextSize.XS}>{children}</RichTuixText>
        ),
        [BLOCKS.HEADING_6]: (node, children: JSX.Element) => (
            <RichTuixText size={TextSize.XS}>{children}</RichTuixText>
        ),
        [BLOCKS.UL_LIST]: (node, children: JSX.Element) => (
            <RichTuixTextList>{children}</RichTuixTextList>
        ),
    },
}
