import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { TUIX_RICH_RENDER_OPTIONS } from 'components/RichContenfulTuix/RichContenfulTuix.consts'
import RichTuixText from 'components/RichContenfulTuix/RichTuixText'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { Link } from 'gatsby'
import React from 'react'
import { TextSize } from 'tuix-webcomponents'
import './ImpressumContent.scss'

type Props = {
    mainContentRichText: string
    extraContentRichText: string
}

export default ({ mainContentRichText, extraContentRichText }: Props): JSX.Element => {
    const { isMobile } = useDeviceWidth()

    const customH2Size = isMobile ? TextSize.XS : TextSize.S

    const customRenderOptions: Options = {
        ...TUIX_RICH_RENDER_OPTIONS,
        renderNode: {
            ...TUIX_RICH_RENDER_OPTIONS.renderNode,
            [BLOCKS.HEADING_2]: (_, children: JSX.Element) => (
                <RichTuixText size={customH2Size}>{children}</RichTuixText>
            ),
            [INLINES.HYPERLINK]: (_, children: JSX.Element) => (
                <Link to={_.data.uri} className="link">
                    {children}
                </Link>
            ),
        },
    }

    const mainContent = documentToReactComponents(
        JSON.parse(mainContentRichText),
        customRenderOptions,
    )
    const extraContent = documentToReactComponents(
        JSON.parse(extraContentRichText),
        customRenderOptions,
    )

    return (
        <div className="impressum__content">
            <div className="extracontent">{extraContent}</div>
            <div className="maincontent">{mainContent}</div>
        </div>
    )
}
