import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React from 'react'
import { TextSize, TextVariant } from 'tuix-webcomponents'

export interface Props {
    message: string
}

export default ({ message }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    return (
        <TuixTextComponent size={TextSize.S} dark={isDarkMode} color={TextVariant.MAIN}>
            {message}
        </TuixTextComponent>
    )
}
