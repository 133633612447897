import React, { useState } from 'react'
import './ThemeToggle.scss'
import { TuixIconComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { DarkPalette, IconName, IconSizeVariant, Palette, TextVariant } from 'tuix-webcomponents'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import { TextSize } from 'tuix-webcomponents'

interface Props {
    theme?: string[]
}

export default ({ theme = ['Theme', 'Light', 'Dark'] }: Props): JSX.Element => {
    const { isDarkMode, enableDarkMode, disableDarkMode } = useDarkModeContext()
    const { isMobile } = useDeviceWidth()
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)

    const toggleTheme = () => {
        const action = isDarkMode ? disableDarkMode : enableDarkMode
        action()
    }

    const hoverStyle = {
        '--variant-primary-hover': isDarkMode ? Palette.PRIMARY_950 : Palette.PRIMARY_50,
    } as React.CSSProperties

    return (
        <>
            {!isMobile && (
                <div
                    onClick={toggleTheme}
                    className="theme-toggle_container"
                    style={{ backgroundColor: isDarkMode ? Palette.PRIMARY_800 : DarkPalette.MAIN }}
                >
                    <TuixIconComponent
                        name={IconName.SUN}
                        color={isDarkMode ? DarkPalette.MAIN : Palette.WHITE}
                        size={IconSizeVariant.SMALL}
                    />
                    <TuixIconComponent
                        name={IconName.MOON}
                        color={isDarkMode ? DarkPalette.WHITE : Palette.PRIMARY_300}
                        size={IconSizeVariant.SMALL}
                    />
                </div>
            )}
            {isMobile && (
                <div
                    className="theme-toggle-mobile_container"
                    onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
                >
                    <div className="theme-toggle-mobile_option" style={hoverStyle}>
                        <TuixTextComponent
                            dark={isDarkMode}
                            size={TextSize.Field2}
                            color={TextVariant.PRIMARY}
                        >
                            {theme[0]}
                        </TuixTextComponent>
                        <TuixIconComponent
                            dark={isDarkMode}
                            name={IconName.CHEVRON_DOWN}
                            size={IconSizeVariant.MEDIUM}
                            color={TextVariant.PRIMARY}
                        />
                    </div>
                    <div
                        className="theme-toggle-mobile_collapsible-menu"
                        style={{
                            display: isOpenMobileMenu ? 'block' : 'none',
                            backgroundColor: isDarkMode
                                ? DarkPalette.ELEVATION_2
                                : Palette.SECTION_BG,
                        }}
                    >
                        <TuixTextComponent
                            className="theme-toggle-mobile_option"
                            style={hoverStyle}
                            onClick={disableDarkMode}
                            dark={isDarkMode}
                            size={TextSize.Field2}
                            color={isDarkMode ? TextVariant.PRIMARY : TextVariant.MAIN}
                        >
                            {theme[1]}
                        </TuixTextComponent>
                        <TuixTextComponent
                            className="theme-toggle-mobile_option"
                            style={hoverStyle}
                            onClick={enableDarkMode}
                            dark={isDarkMode}
                            size={TextSize.Field2}
                            color={!isDarkMode ? TextVariant.PRIMARY : TextVariant.MAIN}
                        >
                            {theme[2]}
                        </TuixTextComponent>
                    </div>
                </div>
            )}
        </>
    )
}
