import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import * as React from 'react'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import ContactData from 'components/ContactData'
import { ContactDataModel } from 'components/ContactData/ContactData'
import './ContactUsData.scss'
import ImageSwitcher from 'components/ImageSwitcher'

interface Props {
    titlePage: string
    subtitlePage: string
    logoImageURL: string
    logoImageDarkURL: string
    contactDataNodes: readonly ContactDataModel[]
}

export default ({
    titlePage,
    subtitlePage,
    logoImageURL,
    contactDataNodes,
    logoImageDarkURL,
}: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className="contactDataContainer">
            <div className="titleTextContainer">
                <TuixTextComponent size={TextSize.XL} dark={isDarkMode}>
                    {titlePage}
                </TuixTextComponent>
                <TuixTextComponent size={TextSize.S} dark={isDarkMode} color={TextVariant.GREY}>
                    {subtitlePage}
                </TuixTextComponent>
            </div>
            <div className="dataContainer">
                <ImageSwitcher lightImageURL={logoImageURL} darkImageURL={logoImageDarkURL} />
                <div className="dataInfoContainer">
                    {contactDataNodes.map((contactData) => (
                        <ContactData
                            key={contactData.text}
                            text={contactData.text}
                            imageURL={contactData.image.file.url}
                            imageDarkURL={contactData.imageDark.file.url}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
