import clsx from 'clsx'
import { TuixIconComponent } from 'components/TuixWebcomponents'
import useClickOutside from 'components/hooks/clickOutside/useClickOutside'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import React, { useState } from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share'
import { IconName, IconSizeVariant, Palette, TextVariant } from 'tuix-webcomponents'
import { isRunningInBrowser } from 'utils/tuixLocalStorage'
import './ShareButton.scss'

type Props = {
    pageTitle: string
}

export default ({ pageTitle }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const [isOpenShareModal, setIsOpenShareModal] = useState(false)
    const currentUrl = isRunningInBrowser ? window.location.toString() : ''
    const shareContentRef = useClickOutside(() => setIsOpenShareModal(false))

    const onShareButtonClick = () => {
        setIsOpenShareModal(!isOpenShareModal)
    }

    const onIconClick = () => {
        setIsOpenShareModal(false)
    }

    const copyToClipboard = (e: React.MouseEvent, text: string) => {
        e.stopPropagation()
        navigator.clipboard.writeText(text)
        setIsOpenShareModal(false)
    }

    return (
        <div ref={shareContentRef} className="share-button-container">
            <TuixIconComponent
                name={IconName.SHARE}
                color={TextVariant.MAIN}
                dark={isDarkMode}
                size={IconSizeVariant.MEDIUM}
                onClick={onShareButtonClick}
            ></TuixIconComponent>
            <div
                className={clsx(
                    'share-options-container',
                    isOpenShareModal && 'open',
                    isDarkMode && 'dark',
                )}
            >
                <LinkedinShareButton url={currentUrl} title={pageTitle} beforeOnClick={onIconClick}>
                    <TuixIconComponent
                        name={IconName.LINKEDIN}
                        size={IconSizeVariant.MEDIUM}
                        fill={isDarkMode ? Palette.MAIN : Palette.DARK_MAIN}
                    />
                </LinkedinShareButton>
                <EmailShareButton url={currentUrl} title={pageTitle} beforeOnClick={onIconClick}>
                    <TuixIconComponent
                        name={IconName.MAIL}
                        size={IconSizeVariant.MEDIUM}
                        fill={isDarkMode ? Palette.MAIN : Palette.DARK_MAIN}
                        color={!isDarkMode && Palette.WHITE}
                    />
                </EmailShareButton>
                <TwitterShareButton url={currentUrl} title={pageTitle} beforeOnClick={onIconClick}>
                    <TuixIconComponent
                        name={IconName.TWITTER}
                        size={IconSizeVariant.MEDIUM}
                        fill={isDarkMode ? Palette.MAIN : Palette.DARK_MAIN}
                    />
                </TwitterShareButton>
                <FacebookShareButton url={currentUrl} quote={pageTitle} beforeOnClick={onIconClick}>
                    <TuixIconComponent
                        name={IconName.FACEBOOK}
                        size={IconSizeVariant.MEDIUM}
                        fill={isDarkMode ? Palette.MAIN : Palette.DARK_MAIN}
                    />
                </FacebookShareButton>
                <TuixIconComponent
                    name={IconName.COPY}
                    size={IconSizeVariant.MEDIUM}
                    color={isDarkMode ? Palette.MAIN : Palette.DARK_MAIN}
                    onClick={(e) => copyToClipboard(e, currentUrl)}
                />
            </div>
        </div>
    )
}
