import Page from 'components/Page'
import { Locale } from '../../utils/enums'
import Section from 'components/Section'
import React from 'react'
import MainImage from 'components/MainImage'
import { getDataByLocale, mappingEdge } from '../../utils/dataHelpers'
import InfoCardBulletPoints from 'components/InfoCardBulletPoints'
import './index.scss'
import TextAndIconCard from 'components/TextAndIconCard'
import { MainImageModel } from 'components/MainImage/MainImage'
import { SectionModel } from 'components/Section/Section'
import { InfoCardBulletPointsModel } from 'components/InfoCardBulletPoints/InfoCardBulletPoints'
import { TextAndIconCardModel } from 'components/TextAndIconCard/TextAndIconCard'
import { Asset } from '../../utils/types'
import { usePageContent } from 'components/hooks/usePageContent/services/usePageContent'
import { MetaData } from 'components/Seo'
import UxUi from 'components/UxUi'
import CurveSvg from 'components/CurveSvg'
import { MainVariant } from 'tuix-webcomponents'
import UxUiTools from 'components/UxUiTools'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'

interface Props {
    locale: Locale
}

type Url = {
    url: string
}

type File = {
    file: Url
}

type FileLogos = {
    url: string
    fileName: string
}

type Logos = {
    file: FileLogos
}

type Description = {
    description: string
}

type UxUiSection = {
    title: string
    description: Description
    uxuiProcessImage: File
    uxuiProcessImageDark: File
    uxUiProcessImageTablet: File
    uxUiProcessImageTabletDark: File
    uxUiProcessImageMobile: File
    uxUiProcessImageMobileDark: File
    sentence: string
    logos: Logos[]
}

export default ({ locale = Locale.de_DE }: Props): JSX.Element => {
    const { isMobile } = useDeviceWidth()

    const {
        nodesMainImage,
        nodesSection1,
        nodesSection2,
        nodesUxUiSection,
        nodesInfoCardBulletPointEdges,
        nodesTextsAndIconCardsEdges,
        nodesCommitHistoryLine,
        nodesMetaData,
    } = usePageContent()

    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)

    const {
        backgroundImage: {
            file: { url },
        },
        backgroundImageDark: {
            file: { url: imageUrlDark },
        },
        title,
        textVariantTitle,
        subtitle,
        textVariantSubtitle,
        textPosition,
        buttonText,
    } = getDataByLocale<MainImageModel>(nodesMainImage, locale)

    const { title: section1Title } = getDataByLocale<SectionModel>(nodesSection1, locale)

    const { title: section2Title } = getDataByLocale<SectionModel>(nodesSection2, locale)

    const UxUiSection = getDataByLocale<UxUiSection>(nodesUxUiSection, locale)

    const {
        file: { url: commitHistoryLineURL },
    } = getDataByLocale<Asset>(nodesCommitHistoryLine, locale)

    const InfoCardBulletPointsNodes = mappingEdge<InfoCardBulletPointsModel>(
        nodesInfoCardBulletPointEdges,
        locale,
    )

    const TextsAndIconCardsNodes = mappingEdge<TextAndIconCardModel>(
        nodesTextsAndIconCardsEdges,
        locale,
    )

    return (
        <Page metaData={metaData} locale={locale}>
            <Section id="services-main-image">
                <MainImage
                    imageURL={url}
                    imageURLDark={imageUrlDark}
                    title={title}
                    textVariantTitle={textVariantTitle}
                    subtitle={subtitle}
                    textVariantSubtitle={textVariantSubtitle}
                    textPosition={textPosition}
                    buttonText={buttonText}
                />
            </Section>
            <Section title={section1Title} id="web-development-and-technologies">
                <div className="content-technologies">
                    {InfoCardBulletPointsNodes.map((infoCardBulletPointNode) => (
                        <InfoCardBulletPoints
                            key={infoCardBulletPointNode.infoCard.text}
                            infoCardEntries={infoCardBulletPointNode.infoCard}
                            bulletPointTexts={infoCardBulletPointNode.bulletPoint.texts}
                            textSide={infoCardBulletPointNode.textSide}
                        />
                    ))}
                    <CurveSvg></CurveSvg>
                </div>
            </Section>
            <Section
                title={UxUiSection.title}
                id="ux-ui-design"
                backgroundColor={MainVariant.SECONDARY}
            >
                <UxUi
                    description={UxUiSection.description.description}
                    locale={locale}
                    uxuiProcessImage={UxUiSection.uxuiProcessImage.file.url}
                    uxuiProcessImageDark={UxUiSection.uxuiProcessImageDark.file.url}
                    uxUiProcessImageTablet={UxUiSection.uxUiProcessImageTablet.file.url}
                    uxUiProcessImageTabletDark={UxUiSection.uxUiProcessImageTabletDark.file.url}
                    uxUiProcessImageMobile={UxUiSection.uxUiProcessImageMobile.file.url}
                    uxUiProcessImageMobileDark={UxUiSection.uxUiProcessImageMobileDark.file.url}
                ></UxUi>
            </Section>
            <Section
                id="ux-ui-tools"
                backgroundColor={isMobile ? MainVariant.PRIMARY : MainVariant.SECONDARY}
            >
                <UxUiTools sentence={UxUiSection.sentence} logos={UxUiSection.logos}></UxUiTools>
            </Section>
            <Section title={section2Title} id="how-do-we-work">
                {TextsAndIconCardsNodes.map((textAndIconCard) => (
                    <TextAndIconCard
                        key={textAndIconCard.textCard.title}
                        IconCardEntries={textAndIconCard.iconCard}
                        TextCardEntries={textAndIconCard.textCard}
                        textSide={textAndIconCard.textSide}
                        bigIcon={textAndIconCard.bigIcon.file.url}
                        bigIconDark={textAndIconCard.bigIconDark.file.url}
                    />
                ))}
            </Section>
        </Page>
    )
}
