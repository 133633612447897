import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { TUIX_RICH_RENDER_OPTIONS } from 'components/RichContenfulTuix/RichContenfulTuix.consts'
import RichTuixText from 'components/RichContenfulTuix/RichTuixText'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import * as React from 'react'
import { TextSize } from 'tuix-webcomponents'
import './ApplyContent.scss'

type Props = {
    offerTitle: string
    offerRawText: string
    applyImageURL: string
}

export default ({ offerTitle, offerRawText, applyImageURL }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const { isMobile, isTablet } = useDeviceWidth()
    const customH5Size = isMobile ? TextSize.S : isTablet ? TextSize.M : TextSize.XS
    const customH6Size = isMobile ? TextSize.XS : isTablet ? TextSize.S : TextSize.Body1

    const customRenderOptions: Options = {
        ...TUIX_RICH_RENDER_OPTIONS,
        renderNode: {
            ...TUIX_RICH_RENDER_OPTIONS.renderNode,
            [BLOCKS.HEADING_5]: (_, children: JSX.Element) => (
                <RichTuixText size={customH5Size}>{children}</RichTuixText>
            ),
            [BLOCKS.HEADING_6]: (_, children: JSX.Element) => (
                <RichTuixText size={customH6Size}>{children}</RichTuixText>
            ),
        },
    }

    const offer = documentToReactComponents(JSON.parse(offerRawText), customRenderOptions)

    return (
        <div className="container-apply-content">
            <TuixTextComponent size={TextSize.XL} className="offer-title" dark={isDarkMode}>
                {offerTitle}
            </TuixTextComponent>
            <img src={applyImageURL} alt="apply image" className="apply-image" />
            <div className="offer-content">{offer}</div>
        </div>
    )
}
